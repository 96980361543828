import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

// import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as Avatar } from '../../../assets/icons/avatar.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import pdfSvg from '../../../assets/icons/pdf.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/three-dots.svg';
import { ActionsPopup } from '../../../components';
import { Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import SearchInput from '../../inputs/search-input';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 10.9rem 11.2rem 10.3rem;
        width: 59.2rem;
    }
`;
const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
  w-full
  mb-[3.6rem]
  mt-[1.6rem]
`;

const StyledCloseIcon = tw(CloseIcon)`
    absolute
    top-[3.5rem]
    right-[3.9rem]
`;
const Card = tw.div`
    flex
    rounded-[8px]
    pl-4
    pr-3
    // py-1
    border
    items-center
    border-[#C7D2FE]
    border-[.5px]
`;

const Share = ({ show, setShow }) => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const close = () => setShow(false);
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <StyledCloseIcon onClick={close} />

            <Card>
                <img src={pdfSvg} alt="" />
                <FlexColumn className="ml-[2rem]">
                    <Text align="left" weight="500" size="1.6rem">
                        Mechanical Engineering.pdf{' '}
                    </Text>
                    <Text align="left" weight="500" lineHeight="2.4rem" color="#6B7280">
                        Size - 156.56 MB
                    </Text>
                </FlexColumn>
                <RelativeContainer className="ml-auto mr-0">
                    <MenuIcon
                        onClick={() => {
                            setDropdownOpen(true);
                        }}
                    />
                    <ActionsPopup
                        open={dropdownOpen}
                        items={[
                            {
                                icon: <DownloadIcon />,
                                name: 'Download',
                                click: () => {
                                    // setShowUploadFile(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                icon: <SendIcon />,
                                name: 'Preview',
                                click: () => {
                                    setDropdownOpen(false);
                                    navigate('/resources/preview');
                                },
                            },
                            {
                                icon: <ShareIcon />,
                                name: 'Share',
                                click: () => {
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                icon: <DeleteIcon />,
                                name: 'Delete',
                                click: () => {
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
                <FlexCentredRow className="mt-[2.7rem]">
                    <ShareIcon />
                    <Text>Share with</Text>
                </FlexCentredRow>
                <FilterContainer>
                    <SearchInput
                        containerWidth="100%"
                        width={'100%'}
                        withRadius
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                </FilterContainer>
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[0.4rem">
                        <Avatar />
                        <Text>MEE 405 Students</Text>
                    </FlexCentredRow>
                    <Button color="#6366F1" border="#6366F1">
                        Send
                    </Button>
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[0.4rem">
                        <Avatar />
                        <Text>MEE 405 Students</Text>
                    </FlexCentredRow>
                    <Button color="#6366F1" border="#6366F1">
                        Send
                    </Button>
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[0.4rem">
                        <Avatar />
                        <Text>MEE 405 Students</Text>
                    </FlexCentredRow>
                    <Button color="#6366F1" border="#6366F1">
                        Send
                    </Button>
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween className="mb-[5.8rem]">
                    <FlexCentredRow className="gap-[0.4rem">
                        <Avatar />
                        <Text>MEE 405 Students</Text>
                    </FlexCentredRow>
                    <Button color="#6366F1" border="#6366F1">
                        Send
                    </Button>
                </FlexRowSpaceBetween>
            </Card>
        </StyledModal>
    );
};

export default Share;
