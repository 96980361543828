import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MarkIcon } from '../../assets/icons/circular-mark.svg';
import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload4.svg';
import { ActionsPopup, AssignRole, ChooseColumnsToExport, ExportSheetDialogue, UploadFile } from '../../components';
import { Button } from '../../components/buttons';
import { PageTitle } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../containers/ScreenContainers';

import Admin from './tabs/Admin';
import Lecturers from './tabs/Lecturers';

const Personnel = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showAssignRole, setShowAssignRole] = useState(false);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);
    const [, /*tab*/ setTab] = useState(0);
    return (
        <div>
            <FlexRowSpaceBetween className="mb-[2rem]">
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <PageTitle>Personnel</PageTitle>
                </FlexCentredRow>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Actions
                        <Dropdown className="ml-[13.15px]" />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        items={[
                            {
                                icon: <UploadIcon />,
                                name: 'Upload Sheet',
                                click: () => {
                                    setShowUploadFile(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                icon: <SendIcon />,
                                name: 'Send Notice',
                                click: () => {
                                    navigate('/communication/send-notice');
                                },
                            },
                            {
                                icon: <MarkIcon />,
                                name: 'Assign Role',
                                click: () => {
                                    setShowAssignRole(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                icon: <MarkIcon />,
                                name: 'Assign Courses',
                                click: () => {
                                    // setShowExportSheetDialogue(true);
                                    setDropdownOpen(false);
                                },
                            },
                            {
                                icon: <ExportIcon />,
                                name: 'Export Sheet',
                                click: () => {
                                    setShowExportSheetDialogue(true);
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Lecturers</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Admin</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Lecturers />
                    </CTabPane>
                    <CTabPane>
                        <Admin />
                    </CTabPane>
                </CTabContent>
            </CTabs>

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
            <AssignRole
                show={showAssignRole}
                close={() => setShowAssignRole(false)}
                // assignAction={}
            />
            <UploadFile show={showUploadFile} setShow={setShowUploadFile} />
        </div>
    );
};

export default Personnel;
