import React from 'react';

import tw from 'twin.macro';

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
    flex
    items-center
    gap-4
    m-0
    cursor-pointer
`;

const Input = tw.input`
    appearance-none
    bg-white
    m-0
    w-10
    h-10
    rounded-[50%]
`;

const RadioInput = ({ name, onChange, checked, label, onClick }) => {
    return (
        <Label onClick={onClick}>
            <Input
                type="radio"
                className={`cursor-pointer ${checked ? 'border-[.8rem] border-[#6366F1] ' : 'border border-[#C4C4C4]'}`}
                onChange={onChange}
                name={name}
            />
            {label}
        </Label>
    );
};

export default RadioInput;
