import React from 'react';

import { FieldArray } from 'formik';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/mark-sm.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-blue.svg';
import Select from '../../../components/inputs/new-select';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input';

import { QUESTION_TYPE_CONSTANSTS, QUESTION_TYPE_LOOKUP } from './utils';

const FillQuestion = ({
    index,
    handleChange,
    handleDelete,
    showDelete,
    isLastItem,
    handleAdd,
    questionType,
    parentName,
    InputType,
    errors,
    question,
    setFieldValue,
    setIsSelectAnswer,
}) => {
    return (
        <div className="grid gap-[24px] bg-white p-[3.5rem] rounded-2xl relative">
            {isLastItem && (
                <div
                    onClick={handleAdd}
                    className="absolute -right-[60px] top-[40px] cursor-pointer p-4 bg-white rounded-[4px]"
                >
                    <PlusIcon />
                </div>
            )}
            <div className="grid grid-cols-2 gap-[24px] text-[#1F2937]">
                <Select
                    name={`${parentName}.questionType`}
                    objProp="name"
                    label="Type"
                    error={errors?.questionType}
                    placeholder="Type"
                    data={QUESTION_TYPE_LOOKUP[questionType]}
                    useComponentState={false}
                    passedSelectedItems={question.questionType ? [{ name: question.questionType }] : null}
                    onChange={(selected) => {
                        setFieldValue(`${parentName}.questionType`, selected[0].name);
                    }}
                />

                <TextInput
                    label="Marks/Points"
                    name={`${parentName}.maxMarks`}
                    type="number"
                    placeholder="Marks/Points"
                    errors={errors}
                    onChange={handleChange}
                    value={question.maxMarks}
                />
            </div>
            <TextAreaInput
                name={`${parentName}.questionText`}
                label={`Question ${index + 1}`}
                value={question.questionText}
                errorMessage={errors?.questionText}
                placeholder="Question"
                height="8rem"
                onChange={handleChange}
            />

            {(question.questionType === QUESTION_TYPE_CONSTANSTS.MultipleChoice ||
                question.questionType === QUESTION_TYPE_CONSTANSTS.Checkbox) && (
                <FieldArray
                    name={`${parentName}.options`}
                    render={({ insert, remove, push }) => {
                        return (
                            <div className="grid gap-[1rem]">
                                {question?.options?.map((option, index) => (
                                    <div key={`Option ${index + 1}`} className="flex mb-5 items-center justify-between">
                                        <div className="flex items-center gap-5">
                                            <InputType />
                                            <input
                                                className="border-b max-w-fit border-b-[#E5E7EB] outline-none placeholder:text-[1.4rem] text-[1.4rem] text-[#1F2937]"
                                                name={`${parentName}.options[${index}]`}
                                                type="text"
                                                placeholder={`Option ${index + 1}`}
                                                errors={errors}
                                                onChange={handleChange}
                                                value={option}
                                            />
                                        </div>

                                        <div className="flex items-center gap-12">
                                            {question.correctAnswer.includes(option) && <CheckIcon />}
                                            <div
                                                onClick={() => remove(index)}
                                                className="cursor-pointer p-4 bg-white rounded-[4px] border border-[#E5E7EB] shadow"
                                            >
                                                <CloseIcon />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    className="flex focus:ring-0 items-center focus:outline-none gap-5 max-w-fit"
                                    onClick={() => push('')}
                                >
                                    <InputType />
                                    <p className="text-[14px] text-[#6B7280] m-0">Add Option</p>
                                </button>
                            </div>
                        );
                    }}
                />
            )}

            {question.questionType === 'German' && (
                <TextInput
                    label="Answer Field"
                    name="Answer Field"
                    type="text"
                    placeholder="Answer Field"
                    errors={errors}
                    onChange={handleChange}
                    value={question.answerField}
                />
            )}

            <div className="flex justify-end">
                <div className="flex gap-[34px] items-center">
                    {showDelete && <DeleteIcon className="cursor-pointer" onClick={handleDelete} />}
                    <p
                        onClick={() => setIsSelectAnswer(true)}
                        className="font-medium cursor-pointer text-[14px] text-[#3B82F6]"
                    >
                        Select Answer
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FillQuestion;
