import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ListEmptyContent from '../../components/list-empty';
import { CenteredContainer } from '../../containers/ScreenContainers';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getActiveSession, getAdmissionSessions } from '../../redux/admissions/actions';
import { getAssignedCourses } from '../../redux/resources/actions';

const fields = [
    {
        accessorKey: 'courseTitle',
        header: 'Course Title',
        cell: (props) => (
            <Link style={{ color: '#2563EB' }} to={`${props.row.original._id}`} state={{ course: props.row.original }}>
                {props.getValue()}
            </Link>
        ),
    },
    {
        accessorKey: 'courseSemester',
        header: 'Semester',
    },
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        header: 'Units',
        accessorKey: 'courseUnit',
    },
    {
        header: 'Registered Students',
        accessorKey: 'numberOfStudent',
    },
];

const LearningResources = () => {
    const dispatch = useDispatch();
    const { sessions, activeSession } = useSelector((store) => store.admission);
    const { assignedCourses } = useSelector((store) => store.resources);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [session, setSession] = useState(activeSession);
    const [openModal, setOpenModal] = useState(null);

    useEffect(() => {
        dispatch(getActiveSession());
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    useEffect(() => {
        // if (!session) return;
        dispatch(getAssignedCourses(session));
    }, [dispatch, session]);

    return (
        <div>
            <PageLayout
                pageTitle="Learning Resources"
                data={assignedCourses || []}
                fields={fields}
                showTableUtils
                searchable
                searchValue={debouncedSearchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                sessionItems={sessions?.map((session) => ({
                    name: session?.admissionYear,
                    click: () => {
                        setSession(session?.admissionYear);
                        setOpenModal(null);
                    },
                }))}
                sessionValue={session}
                openSessionFilter={openModal === 'session'}
                onSessionFilterClick={() => setOpenModal('session')}
                closeSessionFilter={() => setOpenModal(null)}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent title="No resource(s) yet" subtitle="Upload resource(s)"></ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </div>
    );
};

export default LearningResources;
