import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import DateInput from '../../../components/inputs/date-input';
import Select from '../../../components/inputs/new-select';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';

import ComposeAssignment from './Composee';
import { ValidationSchema } from './ValidationSchema';

const FirstStepBox = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
`;

const GridRows = tw.div`
    grid
    grid-cols-2
    gap-x-[32px]
    gap-y-[24px]
    mb-10
`;

const assignmentTypes = [
    { name: 'Platform Based (Composed, solved and graded on the platform)', value: 'Platform Based' },
    { name: 'Score only (Assignments graded outside the platform)', value: 'Score Only' },
];

const CreateAssignment = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { session, course } = state || {};

    const { isLoading } = useSelector((state) => state.assignment);

    const [step, setStep] = useState(1);
    const [storedValues, setStoredValues] = useState({});

    const isDisabled = (values) => {
        const { assignmentType, deadlineTime, deadlineDate, testOption, totalMarks, title } = values;
        if (!assignmentType || !deadlineTime || !deadlineDate || !testOption || !totalMarks || !title) {
            return true;
        }
        setStoredValues(values);
        return false;
    };

    return (
        <div className="pb-20">
            <GoBack
                title={`Assignments / ${course?.courseCode}(${course?.courseTitle})`}
                subTitle="/ Create Assignment"
            />

            <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                {step === 1 ? 'Create Assignment' : 'Compose Assignment'}
            </PageTitle>

            {step === 1 ? (
                <Formik
                    initialValues={{
                        assignmentType: '',
                        title: '',
                        deadlineDate: '',
                        deadlineTime: '',
                        totalMarks: '',
                        testOption: '',
                        session,
                        courseId: course._id,
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        setStep(2);
                    }}
                >
                    {({ errors, handleChange, values, setFieldError, setFieldValue }) => (
                        <Form className="max-w-[1000px]">
                            {step === 1 && (
                                <FirstStepBox>
                                    <FlexCentredRow className="gap-2 mb-8">
                                        <PaperIcon />
                                        <Text weight="600" size="1.6rem">
                                            New Assignment
                                        </Text>
                                    </FlexCentredRow>

                                    <GridRows>
                                        <Select
                                            name="assignmentType"
                                            objProp="name"
                                            label="Assignment Type"
                                            error={errors.assignmentType}
                                            placeholder="Type"
                                            data={assignmentTypes}
                                            passedSelectedItems={values.assignmentType}
                                            onChange={(selected) => {
                                                setFieldValue('assignmentType', selected[0].value);
                                            }}
                                        />
                                        <TextInput
                                            label="Title"
                                            name="title"
                                            type="text"
                                            placeholder="Title"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.title}
                                        />
                                        <DateInput
                                            label="Deadline Date (Optional)"
                                            name="deadlineDate"
                                            onChange={handleChange}
                                            errors={errors}
                                        />
                                        <TextInput
                                            label="Deadline Time (Optional)"
                                            name="deadlineTime"
                                            type="time"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.deadlineTime}
                                        />
                                        <Select
                                            name="continuousAssessment"
                                            label="Continous Assessment"
                                            objProp="name"
                                            passedSelectedItems={values.continuousAssessment}
                                            placeholder="Continous Assessment"
                                            data={['Yes', 'No'].map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('continuousAssessment', selected[0].name);
                                            }}
                                            error={errors.continuousAssessment}
                                        />
                                        <Select
                                            name="testOption"
                                            label="Test"
                                            objProp="name"
                                            passedSelectedItems={values.testOption}
                                            placeholder="Test"
                                            data={['Yes', 'No'].map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('testOption', selected[0].name);
                                            }}
                                            error={errors.testOption}
                                        />
                                        <TextInput
                                            label="Total Marks"
                                            name="totalMarks"
                                            type="number"
                                            placeholder="Total Marks"
                                            errors={errors}
                                            onChange={handleChange}
                                            value={values.totalMarks}
                                        />
                                        <TextAreaInput
                                            name="description"
                                            label="Description"
                                            value={values.description}
                                            errorMessage={errors.description}
                                            placeholder="Description"
                                            height="8rem"
                                            onChange={handleChange}
                                        />
                                    </GridRows>

                                    <FlexRowEnd className="gap-6">
                                        <Button onClick={() => navigate(-1)} border="1px solid #D1D5DB" type="button">
                                            Cancel
                                        </Button>
                                        <LoadingButton disabled={isDisabled(values)} loading={isLoading} type="submit">
                                            Proceed
                                        </LoadingButton>
                                    </FlexRowEnd>
                                </FirstStepBox>
                            )}
                        </Form>
                    )}
                </Formik>
            ) : (
                <ComposeAssignment setStep={setStep} stepOneValues={storedValues} />
            )}
        </div>
    );
};

export default CreateAssignment;
