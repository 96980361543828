import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    assignedCourses: [],
    resourceFolders: [],
    resourceFiles: [],
};

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ASSIGNED_COURSES: (state, action) => {
            state.assignedCourses = action.payload;
        },
        GET_RESOURCES_FOLDERS: (state, action) => {
            state.resourceFolders = action.payload;
        },
        GET_RESOURCE_FILES: (state, action) => {
            state.resourceFiles = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_ASSIGNED_COURSES, GET_RESOURCES_FOLDERS, GET_RESOURCE_FILES } =
    resourcesSlice.actions;

export default resourcesSlice.reducer;
