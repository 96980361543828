import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/document-icon.svg';
import { ReactComponent as Expanded } from '../../assets/icons/expanded-ash.svg';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../containers/ScreenContainers';
//import { getAssignmentById } from '../../redux/assignment/actions';
import { Button } from '../buttons';
import AllocateMark from '../forms/allocate-mark';

const Card = tw.div`
    bg-white
    h-full
    w-full
`;

const Header = tw.div`
    py-[1.6rem]
    mb-[0.2rem]
    pl-[1.6rem]
    pr-[3.1rem]
`;

const Footer = tw.div`
    rounded-b-lg
    bg-[white]
    py-[2.2rem]
    pl-[1.6rem]
    pr-[3.1rem]

`;

const Details = tw.div`
    py-3
    pl-[1.6rem]
    pr-[3.1rem]
`;

const AssignmentAnswer = ({ assignment, showIcons, handleExpand, handleClose }) => {
    // const handleAllocate = () => {};
    const [allocateMark, setAllocateMark] = useState(false);
    const { id } = useParams();
    //const dispatch = useDispatch();
    const { assignment: singleAssignment } = useSelector((store) => store.assignment);

    // useEffect(() => {
    //     if (!id) return;
    //     dispatch(getAssignmentById(id));
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dispatch, id]);

    return (
        <>
            <Card>
                <Header>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <DocumentIcon className="mr-[1.033rem]" />
                            <PageTitle as="p">{assignment.assignmentTitle}</PageTitle>
                        </FlexCentredRow>
                        {showIcons && (
                            <FlexCentredRow>
                                <Expanded className="cursor-pointer" onClick={handleExpand} />
                                <CloseIcon className="m-[2.23rem] cursor-pointer border" onClick={handleClose} />
                            </FlexCentredRow>
                        )}
                    </FlexRowSpaceBetween>
                </Header>
                <Details className="border-y">
                    <Text align="left" weight="500" color="#9CA3AF">
                        Posted {new Date(assignment.createdAt).toLocaleDateString()}
                    </Text>
                    <Text weight="600" lineHeight="2.8rem" align="left" size="1.6rem">
                        {assignment.assignmentTitle}
                    </Text>
                    <div
                        dangerouslySetInnerHTML={{ __html: assignment?.answer }}
                        className="text-[1.6rem] font-normal leading-[2.8rem]"
                    />
                </Details>
                <Footer>
                    <FlexRowEnd>
                        <Button onClick={() => setAllocateMark(true)} bgColor="#6366F1" color="white">
                            Allocate Marks
                        </Button>
                    </FlexRowEnd>
                </Footer>
            </Card>

            <AllocateMark
                show={allocateMark}
                studentId={assignment.studentId}
                maxNumber={singleAssignment.totalMarks}
                id={id}
                close={() => setAllocateMark(false)}
            />
        </>
    );
};

export default AssignmentAnswer;
