export const QUESTION_TYPE_CONSTANSTS = {
    MultipleChoice: 'Multiple Choice',
    Checkbox: 'Checkbox',
    German: 'German',
    ShortAnswer: 'Short Answer',
    LongAnswer: 'Long Answer',
    Upload: 'Upload',
};

export const OBJECTIVE = [
    { name: QUESTION_TYPE_CONSTANSTS.MultipleChoice, value: 'multipleChoice' },
    { name: QUESTION_TYPE_CONSTANSTS.Checkbox, value: 'checkbox' },
    { name: QUESTION_TYPE_CONSTANSTS.German, value: 'german' },
];

export const SUBJECTIVE = [
    { name: QUESTION_TYPE_CONSTANSTS.ShortAnswer, value: 'shortAnswer' },
    { name: QUESTION_TYPE_CONSTANSTS.LongAnswer, value: 'longAnswer' },
    { name: QUESTION_TYPE_CONSTANSTS.Upload, value: 'file' },
];

export const QUESTION_TYPE_LOOKUP = {
    Objective: OBJECTIVE,
    Subjective: SUBJECTIVE,
    Mixed: [...OBJECTIVE, ...SUBJECTIVE],
};
