import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const ConfirmActionDialogue = ({
    children,
    show,
    close,
    svgIcon,
    title,
    subtitle,
    bodyText,
    confirmAction,
    btn2Text,
    input,
    placeholder,
    borderLine,
    bgColor,
    value,
    onChange,
    isLoading,
    withIcon = true,
    noStyle,
    type,
    btnDisabled,
    label,
}) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <FlexCentredRow className="gap-[1.215rem]">
                    {withIcon ? svgIcon ? svgIcon : <WarningIcon fill="#EF4444" /> : null}
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>
                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>
            <Text align="left" size="1.6rem" bottom="1.6rem" color="#374151" top="1.6rem">
                {subtitle}
            </Text>
            {borderLine && <hr className="my-[1.5rem]" />}
            {bodyText && (
                <Text
                    align="left"
                    size="1.6rem"
                    color="#374151"
                    top="1.6rem"
                    bottom="3.2rem"
                    fontStyle={noStyle ? '' : 'italic'}
                >
                    {bodyText}
                </Text>
            )}
            {children}
            {input && (
                <TextInput
                    type="text"
                    placeholder={placeholder || 'Input name'}
                    onChange={onChange}
                    label={label}
                    value={value}
                    required
                />
            )}
            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton
                    loading={isLoading}
                    disabled={btnDisabled || isLoading}
                    onClick={confirmAction}
                    bgColor={bgColor || '#EF4444'}
                    color="#fff"
                >
                    {btn2Text || 'Confirm'}
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ConfirmActionDialogue;
