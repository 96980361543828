import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import InputContainer from '../../../components/Container/inputcontainer';
import CreatePasswordForm from '../../../components/forms/sign-in-new';
import SuccessPopup from '../../../components/popups/success';
import { PoweredBy } from '../../../components/poweredby';
// import { PageTitle } from '../../../containers/MesssageContainers';

export const Container = tw.section`
  bg-[#fff]
  flex
  justify-center
  items-center
  min-h-screen
  max-h-screen
  overflow-y-auto
  bg-center
  w-full
  pt-[10vh]
  pb-[10vh]
`;

const CreatePassword = () => {
    const navigate = useNavigate();

    const [openSuccess, setOpenSuccess] = useState(false);

    return (
        <Container>
            <InputContainer>
                <CreatePasswordForm setOpenSuccess={setOpenSuccess} />
                <PoweredBy />

                <SuccessPopup
                    show={openSuccess}
                    title="Awesome"
                    onClose={() => navigate('/sign-in')}
                    subtitle="Your password has been successfully created. Kindly Sign In to proceed."
                    btnText="Sign In"
                />
            </InputContainer>
        </Container>
    );
};
export default CreatePassword;
