import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { getCourseAssignments, deleteAssignment, publishAssignment } from '../../../redux/assignment/actions';

const fields = [
    {
        label: <Checkbox />,
        key: 'action',
    },
    {
        label: 'Assignment Title',
        key: 'title',
    },
    {
        label: 'Total Marks',
        key: 'totalMarks',
    },
    {
        label: 'Assignment Type',
        key: 'assignmentType',
    },
    {
        label: 'Registered Students',
        key: 'numberOfStudents',
    },
    {
        label: 'Continous Assessment',
        key: 'continuousAssessment',
    },
    {
        label: 'Created At',
        key: 'createdAt',
    },
    {
        label: 'Publish Status',
        key: 'publishStatus',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const CONFIRM_TEXT = 'delete';

const CourseAssignments = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: courseId } = useParams();
    const { state } = useLocation();

    const { session, course } = state || {};
    const { isLoading } = useSelector((state) => state.assignment);

    const [assignments, setAssignments] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { courseCode, courseTitle } = course || {};
    const [openModal, setOpenModal] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(assignments?.length / itemsPerPage);

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const handleInputChange = (e) => {
        setConfirmInputValue(e.target.value);
    };

    const handleCreate = () => navigate(`/assignments/create/${courseId}`, { state: { session, course } });

    const handleDelete = async () => {
        const res = await dispatch(deleteAssignment(selectedItem?._id));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setSelectedItem(null);
        }
    };

    const handlePublish = async () => {
        const res = await dispatch(publishAssignment(selectedItem?._id));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setSelectedItem(null);
        }
    };

    useEffect(() => {
        const getAssignments = async () => {
            const res = await dispatch(getCourseAssignments(session, courseId));
            if (res) {
                setAssignments(res);
                return;
            }
            setAssignments([]);
        };

        getAssignments();
    }, [dispatch, courseId, toggleRefetch, session]);

    return (
        <div className="h-[100vh]">
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <GoBack title="Assignments" subTitle={`/ ${courseCode} (${courseTitle})`} />
                <FlexDiv className="gap-4">
                    <Button onClick={handleCreate}>
                        <PlusIcon className="mr-[1.15rem]" />
                        Create Assignment
                    </Button>
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            Actions
                            <Dropdown className="ml-[13.15px]" />
                        </Button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <SendIcon className="text-[#059669]" />,
                                    name: 'Publish Assignment',
                                    disabled: !selectedItem || selectedItem?.publishStatus === 'Published',
                                    click: () => {
                                        setOpenModal('publish');
                                        setDropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Assignment',
                                    disabled: !selectedItem,
                                    click: () => {
                                        navigate(`/assignments/update/${selectedItem?._id}`, {
                                            state: { assignment: selectedItem, session },
                                        });
                                        setDropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Assignment',
                                    disabled: !selectedItem,
                                    click: () => {
                                        setOpenModal('delete');
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexDiv>
            </FlexRowSpaceBetween>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>
                </FilterContainer>

                <CDataTable
                    items={assignments || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => handleSelect(item)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox checked={item?._id === selectedItem?._id} />
                            </td>
                        ),
                        title: (item) => (
                            <td style={{ color: '#2563EB' }}>
                                <Link
                                    to={`/assignments/submissions/${item?._id}`}
                                    state={{ course, session, assignment: item }}
                                >
                                    {item.title}
                                </Link>
                            </td>
                        ),
                        publishStatus: (item) => (
                            <td>
                                <span
                                    className={`${
                                        item.publishStatus === 'Published' ? ' text-[#059669]' : 'text-[#DC2626]'
                                    }`}
                                >
                                    {item.publishStatus}
                                </span>
                            </td>
                        ),
                        createdAt: (item) => <td>{format(new Date(item.createdAt), 'MMMM dd, yyyy')}</td>,
                    }}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Assignments yet" subtitle="Create a new assignment">
                                <Button onClick={handleCreate} bgColor="#10B981" color="#fff">
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            </ListEmptyContent>
                        </CenteredContainer>
                    }
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            <ConfirmActionDialogue
                input
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete Assignment?"
                subtitle="This Assignment and all attached information will be completely deleted."
                bodyText={`To confirm deletion, enter '${CONFIRM_TEXT}' in the text field.`}
                placeholder={`Input ${CONFIRM_TEXT}`}
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                onChange={handleInputChange}
                value={confirmInputValue}
                isLoading={isLoading}
                btnDisabled={CONFIRM_TEXT !== confirmInputValue.toLowerCase()}
            />
            <ConfirmActionDialogue
                show={openModal === 'publish'}
                close={() => setOpenModal('')}
                title="Publish Assignment?"
                subtitle="This Assignment will be published on the student’s portal.."
                confirmAction={handlePublish}
                btn2Text="Yes, Publish"
                isLoading={isLoading}
                bgColor="#10B981"
                svgIcon={<SendIcon className="text-[#059669]" />}
            />
        </div>
    );
};

export default CourseAssignments;
