import React from 'react';

import { useLocation } from 'react-router-dom';

import Checkbox from '../../../../components/inputs/checkbox';
import RadioInput from '../../../../components/inputs/radio';
import { Text } from '../../../../containers/MesssageContainers';
import { QUESTION_TYPE_CONSTANSTS } from '../../create-assignment/utils';

const Questions = ({ courseTitle }) => {
    const { state } = useLocation();

    const { assignment } = state || {};

    return (
        <section className="pt-[4rem]">
            <Text align="left" weight="600" size="2.3rem">
                {courseTitle}
            </Text>
            <Text align="left" color="#6B7280" size="1.6rem" bottom="3.2rem">
                description
            </Text>

            <ul className="max-w-[1000px] grid gap-6">
                {assignment?.questions?.map((question, index) => {
                    const InputType =
                        question.questionType === QUESTION_TYPE_CONSTANSTS.MultipleChoice ? RadioInput : Checkbox;

                    return (
                        <li key={String(index)} className="bg-white rounded-[16px] px-[3.2rem] py-[3.6rem]">
                            <Text align="left" weight="600">{`Question ${index + 1}`}</Text>
                            <Text align="left" left="1.6rem" top="1.6rem" bottom="3.2rem">
                                {question.questionText}
                            </Text>

                            <div className="grid gap-6">
                                {question.options.map((option) => (
                                    <InputType key={option} label={option} />
                                ))}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

export default Questions;
