import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import {
    LOADING,
    STOP_LOADING,
    SAVE_ALLOTED_COURSES,
    SAVE_STUDENTS,
    SAVE_ALL_COURSES,
    SAVE_TOTAL,
    SAVE_SESSIONS,
} from './slice';

export const getAllotedCourses = (session, semester) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}course/alloted-courses/${session}?semester=${semester}`);
        if (response.status === 200) {
            const courses = response.data.data?.map((item) => ({
                ...item.course,
                students: item.numberOfStudent,
                numberOfCourses: response.data.numberOfCourses,
            }));
            dispatch(SAVE_ALLOTED_COURSES(courses));
            const allData = response.data;
            dispatch(SAVE_TOTAL(allData));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllCourses = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}course/all-courses`);
        if (response.status === 200) {
            dispatch(SAVE_ALL_COURSES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getRegisteredStudents = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}course/all-students/${session}/${courseId}`);
        if (response.status === 200) {
            dispatch(SAVE_STUDENTS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllottedSessions = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}admission/sessions_course_alloted`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(SAVE_SESSIONS(data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
