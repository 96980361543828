import React from 'react';

import { CModal } from '@coreui/react';
import { format } from 'date-fns';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        // padding: 2.4rem;
        width: 59.2rem;
    }
`;

const Details = ({ show, close, createdBy, dateCreated, size }) => {
    const originalDate = new Date(dateCreated || null);
    const formattedDate = format(originalDate, 'yyyy-MM-dd, hh:mm:ss a');
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <div className="flex items-center justify-between border-b-[1px] border-b-[#D1D5DB]">
                <Text
                    align="left"
                    weight="600"
                    color="#1F2937"
                    size="2.3rem"
                    top="2.4rem"
                    bottom="2.4rem"
                    left="1.8rem"
                >
                    Details
                </Text>
                <div className="cursor-pointer p-[1.8rem]">
                    <CloseIcon onClick={close} />
                </div>
            </div>
            <div className="flex flex-col gap-[1.6rem] py-[2.4rem]">
                <div className="flex flex-col gap-[1.6rem] pl-[2.4rem]">
                    <Text align="left" weight="500" color="#1F2937" size="1.4rem">
                        Created By
                    </Text>
                    <Text align="left" weight="400" color="#1F2937" size="1.4rem" left="1rem">
                        {createdBy}
                    </Text>
                </div>
                <div className="flex flex-col gap-[1.6rem] pl-[2.4rem]">
                    <Text align="left" weight="500" color="#1F2937" size="1.4rem">
                        Shared with
                    </Text>
                    <Text align="left" weight="400" color="#1F2937" size="1.4rem" left="1rem">
                        All registered students
                    </Text>
                </div>
                <div className="flex flex-col gap-[1.6rem] pl-[2.4rem]">
                    <Text align="left" weight="500" color="#1F2937" size="1.4rem">
                        Date Created
                    </Text>
                    <Text align="left" weight="400" color="#1F2937" size="1.4rem" left="1rem">
                        {formattedDate}
                    </Text>
                </div>
                <div className="flex flex-col gap-[1.6rem] pl-[2.4rem]">
                    <Text align="left" weight="500" color="#1F2937" size="1.4rem">
                        Size
                    </Text>
                    <Text align="left" weight="400" color="#1F2937" size="1.4rem" left="1rem">
                        {size} MB
                    </Text>
                </div>
            </div>
        </StyledModal>
    );
};

export default Details;
