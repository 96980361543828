import React, { useMemo, useState } from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { styled } from 'twin.macro';

import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import SearchInput from '../../../components/inputs/search-input';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import { moveFileToFolder, moveFolderToFolder } from '../../../redux/resources/actions';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        // padding: 2.4rem;
        width: 59.2rem;
    }
`;

const MoveResourceModal = ({
    show,
    close,
    resourceName,
    allFolders,
    type,
    onSuccess,
    resourceId,
    folderIcon,
    isLoading,
}) => {
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const resourceFolders = useMemo(() => {
        if (debouncedSearchValue) {
            const filteredFolders = allFolders?.filter(
                (item) =>
                    item?.folderName?.toLowerCase()?.includes(debouncedSearchValue?.toLowerCase()) &&
                    item?.folderName?.toLowerCase() !== resourceName?.toLowerCase(),
            );
            return filteredFolders;
        } else {
            return allFolders?.filter((item) => item?.folderName?.toLowerCase() !== resourceName?.toLowerCase());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue]);

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexCentredRow className=" pb-[1rem] mt-[2.4rem] border-b">
                <Text as="h3" size="1.9rem" weight="600" className="pl-[2.4rem]">
                    Move '{resourceName}'
                </Text>
            </FlexCentredRow>

            <Formik
                initialValues={{
                    search: '',
                }}
                onSubmit={async (values, actions) => {
                    const payload = {
                        search: values?.search || debouncedSearchValue,
                    };
                    if (type === 'file') {
                        const res = await dispatch(moveFileToFolder(payload, resourceId));
                        if (res) {
                            onSuccess();
                        }
                    } else {
                        const res = await dispatch(moveFolderToFolder(payload, resourceId));
                        if (res) {
                            onSuccess();
                        }
                    }
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className=" py-[1rem] border-b">
                            <div className="ml-[2.4rem]">
                                <SearchInput
                                    width="45rem"
                                    onClose={() => setSearchValue('')}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    placeholder="Input name"
                                    className="border mt-[1rem] ml-[2.4rem] p-2 rounded"
                                />
                            </div>
                        </div>

                        <div className="">
                            {resourceFolders?.map((folder) => (
                                <FlexCentredRow
                                    onClick={() => {
                                        setSearchValue(folder?.folderName);
                                        setFieldValue('search', folder?.folderName);
                                    }}
                                    key={folder?._id}
                                    className={`${
                                        searchValue === folder?.folderName ? 'bg-gray-200' : ''
                                    } gap-[1rem] py-[0.5rem] mt-[1rem] pl-[3.4rem] cursor-pointer hover:bg-gray-200`}
                                >
                                    {folderIcon}
                                    <Text align="left" weight="400" size="1.4rem" lineHeight="2.4rem">
                                        {folder?.folderName}
                                    </Text>
                                </FlexCentredRow>
                            ))}
                        </div>

                        <FlexRowEnd className="gap-[1.5rem] mt-[1rem] mr-[2.4rem] mb-[2.4rem]">
                            <CancelButton type="button" onClick={close}>
                                Cancel
                            </CancelButton>
                            <LoadingButton disabled={!searchValue} loading={isLoading} type="submit">
                                Move
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </StyledModal>
    );
};

export default MoveResourceModal;
