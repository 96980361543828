import React, { useEffect, useState } from 'react';

import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import Select from '../../../components/inputs/new-select';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { createAssignment } from '../../../redux/assignment/actions';
import { formatTime } from '../../../utils';

import Question from './Question';
import { ComposeValidationSchema } from './ValidationSchema';

const SELECT_DATA = ['Yes', 'No'].map((item) => ({ name: item, value: item }));

const QUESTION_TYPES = [
    { name: 'Objective (Multiple choice, Checkboxes, German)', value: 'Objective' },
    { name: 'Subjective (Short answer, Long answer, File upload)', value: 'Subjective' },
    { name: 'Mixed', value: 'Mixed' },
];

const SaveFormValues = ({ stepOneValues }) => {
    const dispatch = useDispatch();
    const { values } = useFormikContext();

    useEffect(() => {
        // console.log('useeffect saving');
        const { session, courseId, ...otherStepOnevalues } = stepOneValues;

        // const interval = setInterval(() => {
        const timer = setTimeout(() => {
            const payload = {
                ...otherStepOnevalues,
                deadlineTime: formatTime(stepOneValues.deadlineTime),
                ...values,
            };
            // console.log('useeffect calling');

            dispatch(createAssignment(courseId, session, payload));
            // }, 120000); // 120000 milliseconds = 2 minutes
        }, 5000); // 5000ms = 5 seconds

        return () => clearTimeout(timer);
    }, [dispatch, stepOneValues, values]);

    return null;
};

const ComposeAssignment = ({ setStep, stepOneValues }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { session, courseId, ...otherStepOnevalues } = stepOneValues;

    const { isLoading: isSaving } = useSelector((state) => state.assignment);

    const [isPublishing, setIsPublishing] = useState(false);

    return (
        <div className="max-w-[1000px]">
            <Formik
                initialValues={{
                    instructions: '',
                    showMarkForEachQuestion: '',
                    showResultImmediately: '',
                    questionType: '',
                    questions: [
                        {
                            questionText: '',
                            questionType: '',
                            options: [],
                            correctAnswer: [],
                            maxMarks: 0,
                        },
                    ],
                }}
                validationSchema={ComposeValidationSchema}
                onSubmit={async (values, actions, errors) => {
                    setIsPublishing(true);
                    const useLoader = false;

                    const payload = {
                        ...otherStepOnevalues,
                        deadlineTime: formatTime(stepOneValues.deadlineTime),
                        ...values,
                    };
                    const res = await dispatch(createAssignment(courseId, session, payload, useLoader));
                    if (res) {
                        setIsPublishing(false);
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldError, setFieldValue }) => (
                    <Form className="grid gap-[16px]">
                        <SaveFormValues stepOneValues={stepOneValues} />

                        <div className="flex bg-white justify-between items-center h-[81px] rounded-[16px] px-[32px] py-[10px] mb-[16px]">
                            <div className="flex gap-10 items-center">
                                <h3 className="text-[16px] font-medium">{stepOneValues?.title}</h3>
                                <p className="text-[#6B7280] text-[12px]">
                                    {isSaving ? 'Saving changes...' : 'Changes are saved automatically'}
                                </p>
                            </div>
                            <div className="flex gap-8">
                                <CancelButton onClick={() => setStep(1)}>Exit</CancelButton>
                                <LoadingButton loading={isPublishing} type="submit">
                                    Publish
                                </LoadingButton>
                            </div>
                        </div>

                        <div className="bg-white p-[3.5rem] rounded-2xl">
                            <FlexCentredRow className="gap-2 mb-8">
                                <PaperIcon />
                                <Text weight="600" size="1.6rem">
                                    New Assignment
                                </Text>
                            </FlexCentredRow>

                            <div className="grid grid-cols-2 gap-[24px] text-[#1F2937]">
                                <TextInput
                                    label="Instructions"
                                    name="instructions"
                                    type="text"
                                    placeholder="Instructions"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.instructions}
                                />
                                <Select
                                    name="questionType"
                                    objProp="name"
                                    label="Question Type"
                                    error={errors.questionType}
                                    placeholder="Question Type"
                                    data={QUESTION_TYPES}
                                    onChange={(selected) => {
                                        setFieldValue('questionType', selected[0].value);
                                    }}
                                />
                                {values.questionType === 'Objective' && (
                                    <Select
                                        name="showResultImmediately"
                                        objProp="name"
                                        label="Show Results immediately"
                                        error={errors.showResultImmediately}
                                        placeholder="Show Results"
                                        data={SELECT_DATA}
                                        onChange={(selected) => {
                                            setFieldValue('showResultImmediately', selected[0].name);
                                        }}
                                    />
                                )}
                                <Select
                                    name="showMarkForEachQuestion"
                                    objProp="name"
                                    label="Show Marks for each question"
                                    error={errors.showMarkForEachQuestion}
                                    placeholder="Show Marks"
                                    data={SELECT_DATA}
                                    onChange={(selected) => {
                                        setFieldValue('showMarkForEachQuestion', selected[0].name);
                                    }}
                                />
                            </div>
                        </div>

                        <FieldArray
                            name="questions"
                            render={({ remove, push }) => (
                                <div className="grid gap-[1rem]">
                                    {values?.questions?.map((question, index) => (
                                        <Question
                                            key={String(index)}
                                            index={index}
                                            question={question}
                                            questionType={values.questionType}
                                            isLastItem={index === values.questions.length - 1}
                                            parentName={`questions.${index}`}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            showDelete={values.questions.length > 1}
                                            handleDelete={values.questions.length > 1 ? () => remove(index) : null}
                                            handleAdd={() =>
                                                push({
                                                    questionText: '',
                                                    questionType: '',
                                                    options: [],
                                                    correctAnswer: [],
                                                    maxMarks: 0,
                                                })
                                            }
                                            errors={errors.questions?.[index]}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ComposeAssignment;
