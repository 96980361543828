import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { stringifyJSON } from '../../utils/useJSON';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../admissions/actions';
import { getAllottedSessions } from '../alloted-courses/actions';
// import { getAllReceived } from '../communications/actions';
import { getLectureTimetable } from '../schedule/actions';
import { getStaffDetails } from '../staff/actions';
import { SAVE_USER } from '../user/slice';

import { LOADING, STOP_LOADING } from './slice';

export const loginUser = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/login`, payload);
        if (response.status === 200) {
            const { accessToken, data } = response.data;
            await stringifyJSON('token', accessToken);
            await dispatch(SAVE_USER(data));
            await dispatch(getLectureTimetable());
            await dispatch(getActiveSession());
            dispatch(getActiveSemester());
            dispatch(getAdmissionSessions());
            //TODO uncomment this when BE is done. currently 401 error
            // dispatch(getAllReceived());
            dispatch(getStaffDetails());
            dispatch(getAllottedSessions());

            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createPassword = (token, payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/create-password?token=${token}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const forgetPassword = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/forgot_password`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage =
            error?.response?.data?.Message || 'An error occurred, please check if you have entered the right email.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const resetPassword = (token, payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/reset_password/${token}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
