import React, { useEffect, useRef, useState } from 'react';

import { ActionMenu } from '@belrald_hq/belrald-ui';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { format } from 'date-fns';
import Avatar from 'react-avatar';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CircularPlus } from '../../../assets/icons/circular-plus-black.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as PreviewIcon } from '../../../assets/icons/external-Link.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/folder.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/move.svg';
import { ReactComponent as RenameIcon } from '../../../assets/icons/rename-icon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload3.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import TableWithUtils from '../../../components/table-with-utils';
import { Text } from '../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import {
    createFolder,
    deleteResourceFile,
    deleteResourceFolder,
    getNestedFiles,
    getNestedFolders,
    getResourceFiles,
    getResourceFolders,
    renameResourceFile,
    renameResourceFolder,
    shareResourceFile,
    shareResourceFolder,
    uploadFile,
} from '../../../redux/resources/actions';
import {
    allowedTypes,
    getFileIcon,
    getStatus,
    handleDownload,
    handleImagePreview,
    handleVideoPreview,
    imageTypes,
    videoTypes,
} from '../../../utils';
import Details from '../details';
import MoveResourceModal from '../move-resource';

const items = ({ selectedTab, handleOpenModal, handleDownload, props, itemId }) => [
    {
        icon: <ShareIcon />,
        name: 'Share with students',
        click: () => {
            handleOpenModal('share', itemId);
        },
    },
    {
        icon: <UploadIcon />,
        name: 'Download',
        click: () => {
            handleDownload(props?.row?.original?.url, props?.row?.original?.fileName);
        },
        notShown: selectedTab === 'folder',
    },
    {
        icon: <PreviewIcon />,
        name: 'Preview',
        click: () => {
            if (videoTypes?.includes(props?.row?.original?.fileType)) {
                const videoUrl = props?.row?.original?.url;
                handleVideoPreview(videoUrl);
            } else if (imageTypes?.includes(props?.row?.original?.fileType)) {
                handleImagePreview(props?.row?.original?.url);
            } else {
                const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
                    props?.row?.original?.url,
                )}&embedded=true`;
                window.open(googleViewerUrl, '_blank');
            }
        },
        notShown: selectedTab === 'folder',
    },
    {
        icon: <WarningIcon fill="#1F2937" />,
        name: 'Details',
        click: () => {
            handleOpenModal('details', null, props?.row?.original);
        },
    },
    {
        icon: <CopyIcon />,
        name: 'Copy link',
        click: () => {
            const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
                props?.row?.original?.url,
            )}&embedded=true`;
            navigator.clipboard.writeText(googleViewerUrl);
            handleOpenModal(null);
        },
        notShown: !props?.row?.original?.url,
    },
    {
        icon: <MoveIcon />,
        name: 'Move to folder',
        click: () => {
            handleOpenModal('move', itemId, props?.row?.original);
        },
    },
    {
        icon: <RenameIcon />,
        name: 'Rename',
        click: () => {
            handleOpenModal('rename', itemId, props?.row?.original);
        },
    },
    {
        icon: <DeleteIcon />,
        name: 'Delete',
        click: () => {
            handleOpenModal('delete', itemId, props?.row?.original);
        },
    },
];

const CourseResources = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state, search } = useLocation();
    const { isLoading, resourceFolders, resourceFiles } = useSelector((store) => store.resources);
    const hiddenFileInput = useRef(null);
    const { id: courseId, folderId } = useParams();

    const { course, backLink } = state || {};
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [refetchData, setRefetchData] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [selectedResource, setSelectedResource] = useState(null);
    const { dateCreated, createdAt, owner, size, fileName, folderName } = selectedResource || {};
    const [filteredFiles, setFilteredFiles] = useState(resourceFiles);
    const [filteredFolders, setFilteredFolders] = useState(resourceFolders);

    const activeTab = search?.split('=')[1];
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        if (activeTab === 'folder') {
            setSelectedTab('folder');
        } else {
            setSelectedTab('file');
        }
    }, [activeTab]);

    const convertedSize = size > 0 && (size / (1024 * 1024)).toFixed(2);

    const generateFields = () => [
        {
            accessorKey: selectedTab === 'folder' ? 'folderName' : 'fileName',
            header: selectedTab === 'folder' ? 'Folder Name' : 'File Name',
            cell: (props) => {
                const itemId = selectedTab === 'folder' ? props?.row?.original?._id : props?.row?.original?.fileId;
                return (
                    <p
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                            if (selectedTab === 'folder') {
                                navigate(`/resources/${courseId}/${itemId}`, {
                                    state: { course: course, backLink: `/ ${props?.row?.original?.folderName}` },
                                });
                            }
                        }}
                    >
                        {selectedTab === 'folder' ? <FolderIcon /> : getFileIcon(props?.row?.original?.fileType)}{' '}
                        {props.getValue()}
                    </p>
                );
            },
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            cell: (props) => {
                const originalDate = new Date(props?.row?.original?.dateCreated);
                const formattedDate = format(originalDate, 'yyyy-MM-dd, hh:mm:ss a');
                return (
                    <Text align="left" size="1.4rem" weight="400" color="#6B7280">
                        {formattedDate}
                    </Text>
                );
            },
        },
        {
            header: 'Created By',
            accessorKey: 'owner',
            cell: (props) => {
                if (props?.row?.original?.profilePicture?.url) {
                    return (
                        <div className="flex items-center gap-2 w-[24px] h-[24px] rounded-full">
                            <img
                                src={props?.row?.original?.profilePicture?.url}
                                alt={props?.row?.original?.owner}
                                className="rounded-full"
                            />
                            <Text size="1.4rem" weight="400" color="#1F2937">
                                {props?.getValue()}
                            </Text>
                        </div>
                    );
                } else {
                    return (
                        <div className="flex items-center gap-2">
                            <Avatar size="24" textSizeRatio={1.75} className="rounded-full" />
                            <Text size="1.4rem" weight="400" color="#1F2937">
                                {props?.getValue()}
                            </Text>
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'sharedStatus',
            header: 'Shared Status',
            cell: (props) => {
                return getStatus(props?.row?.original?.shared);
            },
        },
        {
            header: '',
            accessorKey: 'action',
            cell: (props) => {
                const itemId = selectedTab === 'folder' ? props?.row?.original?._id : props?.row?.original?.fileId;
                return (
                    <div className="">
                        <ActionMenu
                            options={items({
                                selectedTab,
                                handleOpenModal,
                                handleDownload,
                                props,
                                itemId,
                            })
                                .filter((item) => !item.notShown)
                                .map((item) => ({
                                    label: item.name,
                                    icon: item.icon,
                                    onOptionClick: item.click,
                                }))}
                        />
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (folderId && selectedTab === 'folder') {
            dispatch(getNestedFolders(folderId));
        } else if (folderId && selectedTab === 'file') {
            dispatch(getNestedFiles(folderId));
        } else if (!folderId && selectedTab === 'folder') {
            dispatch(getResourceFolders());
        } else if (!folderId && selectedTab === 'file') {
            dispatch(getResourceFiles());
        }
    }, [dispatch, refetchData, folderId, selectedTab]);

    const onSuccess = () => {
        setRefetchData(!refetchData);
        handleOpenModal(null);
    };

    const onCreateFolder = async () => {
        const response = await dispatch(createFolder({ folderName: inputValue }));
        if (response) {
            onSuccess();
        }
    };

    const uploadResourceFile = async () => {
        const formData = new FormData();
        formData.append('content', attachment);
        const response = await dispatch(uploadFile(formData));
        if (response) {
            setAttachment(null);
            onSuccess();
        }
    };

    const onShareResource = async () => {
        const payload = { courseId };
        if (selectedTab === 'file') {
            const response = await dispatch(shareResourceFile(payload, selectedId));
            if (response) {
                onSuccess();
            }
        } else {
            const response = await dispatch(shareResourceFolder(payload, selectedId));
            if (response) {
                onSuccess();
            }
        }
    };

    const onRenameResource = async () => {
        if (selectedTab === 'file') {
            const payload = { fileId: selectedId, newName: inputValue };
            const response = await dispatch(renameResourceFile(payload));
            if (response) {
                onSuccess();
            }
        } else {
            const payload = { folderId: selectedId, newName: inputValue };
            const response = await dispatch(renameResourceFolder(payload));
            if (response) {
                onSuccess();
            }
        }
    };

    const onDeleteResource = async () => {
        if (selectedTab === 'file') {
            const payload = { fileId: selectedId };
            const response = await dispatch(deleteResourceFile(payload));
            if (response) {
                onSuccess();
            }
        } else {
            const payload = { folderId: selectedId };
            const response = await dispatch(deleteResourceFolder(payload));
            if (response) {
                onSuccess();
            }
        }
    };

    useEffect(() => {
        if (attachment) {
            uploadResourceFile();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachment]);

    const handleOpenModal = (dropdown, resourceId, resource) => {
        if (dropdown === openDropdown || !dropdown) {
            setOpenDropdown(null);
            setSelectedId('');
            setSelectedResource(null);
            setInputValue('');
        } else {
            setOpenDropdown(dropdown);
            setSelectedId(resourceId);
            setSelectedResource(resource);
            dropdown === 'rename' && setInputValue(resource?.fileName || resource?.folderName);
        }
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const addAttachment = (e) => {
        const file = e.target.files[0];
        if (allowedTypes.includes(file.type)) {
            setAttachment(file);
        } else {
            toast.error('Invalid file type');
        }
    };

    useEffect(() => {
        if (debouncedSearchValue && selectedTab === 'file') {
            const filteredFiles = resourceFiles?.filter((file) =>
                file?.fileName?.toLowerCase()?.includes(debouncedSearchValue?.toLowerCase()),
            );
            setFilteredFiles(filteredFiles);
        } else {
            setFilteredFiles(resourceFiles);
        }
        //eslint-disable-next-line
    }, [debouncedSearchValue, resourceFiles]);

    useEffect(() => {
        if (debouncedSearchValue && selectedTab === 'folder') {
            const filteredFolders = resourceFolders?.filter((file) =>
                file?.folderName?.toLowerCase()?.includes(debouncedSearchValue?.toLowerCase()),
            );
            setFilteredFolders(filteredFolders);
        } else {
            setFilteredFolders(resourceFolders);
        }
        //eslint-disable-next-line
    }, [debouncedSearchValue, resourceFolders]);

    return (
        <div>
            <PageLayout
                backTitle="Learning Resources"
                backSubtitle={course?.courseCode}
                childrenLinks={backLink}
                pageTitle={`${course?.courseTitle} (${course?.courseCode})`}
                actionText="New"
                actionIconStart={<CircularPlus />}
                actionBgColor="#fff"
                actionTextColor="#1F2937"
                onActionBtnClick={() => handleOpenModal('dropdown')}
                actionOpen={openDropdown === 'dropdown'}
                actionClose={() => handleOpenModal(null)}
                actionItems={[
                    {
                        icon: <FolderIcon />,
                        name: 'Create Folder',
                        click: () => {
                            handleOpenModal('create-folder');
                        },
                    },
                    {
                        icon: <UploadIcon />,
                        name: 'Upload File',
                        click: () => {
                            handleClick();
                        },
                    },
                    {
                        icon: <DownloadIcon />,
                        name: 'Import Resources',
                        // click: () => {
                        //     setOpenModal('send-offer');
                        //     setIsActionsDropdownOpen(false);
                        // },
                    },
                ]}
                tabComponent={
                    <div className="mt-12">
                        <CTabs>
                            <CNav variant="tabs" className="">
                                <CNavItem>
                                    <CNavLink
                                        onClick={() => {
                                            setSelectedTab('file');
                                            navigate(`?active=file`, { state: { course: course } });
                                        }}
                                        active={selectedTab === 'file'}
                                    >
                                        Files
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink
                                        onClick={() => {
                                            setSelectedTab('folder');
                                            navigate(`?active=folder`, { state: { course: course } });
                                        }}
                                        active={selectedTab === 'folder'}
                                    >
                                        Folders
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent>
                                {selectedTab === 'file' && (
                                    <CTabPane>
                                        <TableWithUtils
                                            noMargin
                                            fields={generateFields()}
                                            data={filteredFiles || []}
                                            showTableUtils
                                            searchable
                                            searchValue={searchValue}
                                            onSearchChange={(e) => setSearchValue(e.target.value)}
                                            onSearchClose={() => setSearchValue('')}
                                            noItemView={
                                                <CenteredContainer className="mt-[5rem]">
                                                    <ListEmptyContent title="No file(s) yet"></ListEmptyContent>
                                                </CenteredContainer>
                                            }
                                            loading={isLoading}
                                        />
                                    </CTabPane>
                                )}
                                {selectedTab === 'folder' && (
                                    <CTabPane active={selectedTab === 'folder'}>
                                        <TableWithUtils
                                            noMargin
                                            fields={generateFields()}
                                            data={filteredFolders || []}
                                            showTableUtils
                                            searchable
                                            searchValue={searchValue}
                                            onSearchChange={(e) => setSearchValue(e.target.value)}
                                            onSearchClose={() => setSearchValue('')}
                                            noItemView={
                                                <CenteredContainer className="mt-[5rem]">
                                                    <ListEmptyContent title="No file(s) yet"></ListEmptyContent>
                                                </CenteredContainer>
                                            }
                                            loading={isLoading}
                                        />
                                    </CTabPane>
                                )}
                            </CTabContent>
                        </CTabs>
                    </div>
                }
            />
            <ConfirmActionDialogue
                show={openDropdown === 'create-folder'}
                close={() => handleOpenModal(null)}
                withIcon
                svgIcon={<CircularPlus />}
                title="Create Folder"
                input
                label="Name"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                btn2Text="Create"
                isLoading={isLoading}
                btnDisabled={!inputValue}
                confirmAction={onCreateFolder}
                bgColor="#6366F1"
            />
            <ConfirmActionDialogue
                show={openDropdown === 'share'}
                close={() => handleOpenModal(null)}
                withIcon
                svgIcon={<ShareIcon />}
                title={`Share ${course?.courseCode} with students`}
                children={
                    <p className="text-[1.6rem] text-[#374151]">
                        This learning resources will be shared with all registered students for{' '}
                        <strong>{course?.courseCode}</strong>
                    </p>
                }
                btn2Text="Send"
                isLoading={isLoading}
                confirmAction={onShareResource}
                bgColor="#6366F1"
            />
            <ConfirmActionDialogue
                show={openDropdown === 'rename'}
                close={() => handleOpenModal('rename')}
                withIcon
                svgIcon={<RenameIcon />}
                title={`Rename ${selectedTab === 'file' ? 'File' : 'Folder'}`}
                input
                label="Name"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                btn2Text="Done"
                isLoading={isLoading}
                btnDisabled={!inputValue}
                confirmAction={onRenameResource}
                bgColor="#6366F1"
            />
            <ConfirmActionDialogue
                show={openDropdown === 'delete'}
                close={() => handleOpenModal(null)}
                withIcon
                svgIcon={<WarningIcon fill="#EF4444" />}
                subtitle="Are you sure you want to delete this permanently?"
                title="Delete Item"
                btn2Text="Yes, Delete"
                isLoading={isLoading}
                confirmAction={onDeleteResource}
            />
            <input
                type="file"
                style={{ display: 'none' }}
                name="pdf"
                // accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpeg,.jpg"
                ref={hiddenFileInput}
                onChange={(e) => {
                    addAttachment(e);
                }}
            />
            <Details
                show={openDropdown === 'details'}
                close={() => handleOpenModal(null)}
                createdBy={owner}
                dateCreated={dateCreated || createdAt}
                size={convertedSize}
            />
            <MoveResourceModal
                show={openDropdown === 'move'}
                close={() => handleOpenModal(null)}
                resourceName={fileName || folderName}
                allFolders={resourceFolders}
                resourceId={selectedId}
                folderIcon={<FolderIcon />}
                isLoading={isLoading}
                type={selectedTab}
                onSuccess={onSuccess}
            />
        </div>
    );
};

export default CourseResources;
