import React from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { GoBack } from '../../../components/go-back';
// import { Loader } from '../../../components/loader';

import Questions from './tabs/Questions';
import Submissions from './tabs/Submissions';

const AssignmentSubmissions = () => {
    const { state } = useLocation();

    const {
        assignment,
        // isLoading
    } = useSelector((store) => store.assignment);

    // if (isLoading) return <Loader />;

    return (
        <>
            <div className="mb-10">
                <GoBack title={`Assignments / ${assignment?.courseCode}`} subTitle={`/ ${state.assignment.title}`} />
            </div>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>Questions</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Submissions 0</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Questions courseTitle={assignment.courseTitle} />
                    </CTabPane>
                    <CTabPane>
                        <Submissions />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </>
    );
};

export default AssignmentSubmissions;
