/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, ItemsPerPageCont } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import { getStudents } from '../../../../redux/students/actions';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'name',
    },
    {
        label: 'Matric No.',
        key: 'matricNumber',
    },
    {
        label: 'Score',
        key: 'score',
    },
];
const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Submissions = () => {
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const students = [];
    const {
        // students,
        isLoading,
    } = useSelector((state) => state.students);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(students?.length / itemsPerPage);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    // useEffect(() => {
    //     dispatch(getStudents(query));
    // }, [dispatch, query]);

    return (
        <div className="max-w-[1000px]">
            <FlexCentredRow>
                <PageTitle>Students</PageTitle>
            </FlexCentredRow>

            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                </FilterContainer>

                <CDataTable
                    items={students || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    // onRowClick={(item) => navigate(`/student/${item._id}`)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => (
                            <td
                            // style={{ color: '#2563EB' }}
                            // onClick={() => navigate(`/student/${item._id}`, { state: { student: item } })}
                            >
                                {item.lastName}
                            </td>
                        ),
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />
                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </div>
    );
};
export default Submissions;
