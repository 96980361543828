// REMOVE "fill" PROPERTIES IN ANY SVG THAT NEEDS TO BE USED HERE
// HELPS FOR DYNAMIC COLOR CHANGES ON ACTIVE SELECTION
import { ReactComponent as AllotedIcon } from '../assets/icons/sb-alloted.svg';
import { ReactComponent as AssignmentsIcon } from '../assets/icons/sb-assignments.svg';
import { ReactComponent as CommunicationsIcon } from '../assets/icons/sb-communications.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/sb-dashboard.svg';
import { ReactComponent as HandbookIcon } from '../assets/icons/sb-handbook.svg';
import { ReactComponent as PeopleIcon } from '../assets/icons/sb-people.svg';
import { ReactComponent as ResourcesIcon } from '../assets/icons/sb-resources.svg';
import { ReactComponent as ResultIcon } from '../assets/icons/sb-results.svg';
import { ReactComponent as SchedulesIcon } from '../assets/icons/sb-schedules.svg';
// import { ReactComponent as SettingsIcon } from '../assets/icons/sb-settings.svg';

const developmentFlag = process.env.REACT_APP_ENV;

export const navLinks = [
    {
        title: 'Dashboard',
        to: '/dashboard',
        src: DashboardIcon,
    },
    {
        title: 'Students',
        to: '/student',
        src: PeopleIcon,
    },
    {
        title: 'Allotted Courses',
        to: '/allotted-courses',
        src: AllotedIcon,
    },
    ...(developmentFlag !== 'production'
        ? [
              {
                  title: 'Results',
                  to: '/results',
                  src: ResultIcon,
              },
          ]
        : []),
    {
        title: 'Attendance',
        to: '/attendance',
        src: AssignmentsIcon,
        subMenu: [
            {
                title: 'Attendance',
                to: '/attendance',
            },
            {
                title: 'Scoresheet',
                to: '/scoresheet-attendance',
            },
        ],
    },
    {
        title: 'Learning Resources',
        to: '/resources',
        src: ResourcesIcon,
    },
    {
        title: 'Assignments',
        to: '/assignments',
        src: AssignmentsIcon,
        subMenu: [
            {
                title: 'Assignments',
                to: '/assignments',
            },
            {
                title: 'Scoresheet',
                to: '/assignment-scoresheet',
            },
        ],
    },
    {
        title: 'Tests',
        to: '/tests',
        src: AssignmentsIcon,
        subMenu: [
            {
                title: 'Tests',
                to: '/tests',
            },
            {
                title: 'Scoresheet',
                to: '/test-scoresheet',
            },
        ],
    },
    {
        title: 'Exams',
        to: '/exams',
        src: AssignmentsIcon,
        subMenu: [
            {
                title: 'Exams',
                to: '/exams',
            },
            {
                title: 'Scoresheet',
                to: '/exam-scoresheet',
            },
        ],
    },
    {
        title: 'Broadsheet',
        to: '/broadsheet',
        src: AssignmentsIcon,
        subMenu: [
            {
                title: 'CA Broadsheet',
                to: '/ca-broadsheet',
            },
            {
                title: 'Exam Broadsheet',
                to: '/exam-broadsheet',
            },
            {
                title: 'Settings',
                to: '/broadsheet-settings',
            },
        ],
    },
    {
        title: 'Schedules',
        to: '/schedules',
        src: SchedulesIcon,
    },
    {
        title: 'Handbook',
        to: '/handbook',
        src: HandbookIcon,
    },
    ...(developmentFlag !== 'production'
        ? [
              {
                  title: 'Communications',
                  to: '/communications',
                  src: CommunicationsIcon,
                  subMenu: [
                      {
                          title: 'Received',
                          to: '/communications/received',
                      },
                      {
                          title: 'Sent',
                          to: '/communications/sent',
                      },
                      {
                          title: 'Draft',
                          to: '/communications/draft',
                      },
                  ],
              },
          ]
        : []),
    // {
    //     title: 'Settings',
    //     to: '/settings',
    //     src: SettingsIcon,
    // },
];
