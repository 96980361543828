import { toast } from 'react-hot-toast';
import { styled } from 'twin.macro';

import { ReactComponent as ExcelIcon } from '../assets/icons/excel.svg';
import { ReactComponent as ImageIcon } from '../assets/icons/Image.svg';
import { ReactComponent as MSWordIcon } from '../assets/icons/microsoft-word.svg';
import { ReactComponent as PDFIcon } from '../assets/icons/pdf.svg';
import { ReactComponent as PPTXIcon } from '../assets/icons/pptx-icon.svg';
import { ReactComponent as VideoIcon } from '../assets/icons/Video.svg';
import { Text } from '../containers/MesssageContainers';

export const semesters = ['First Semester', 'Second Semester'];

export const encodeQuery = (queryObj) => {
    const encodedQuery = Object.entries(queryObj).map(([key, value]) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value.toString());
    });
    return encodedQuery.join('&');
};

export const generateWeeklyEvents = (data, weekCount = 13) => {
    const events = [];
    const startDate = new Date(data.startTime);
    const endDate = new Date(data.endTime);

    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();

    const weekdayNumber = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
        data.weekday,
    );

    const currentDate = new Date(startDate);
    for (let i = 0; i < weekCount; i++) {
        const weekday = currentDate.getDay();
        const daysUntilWeekday = (weekdayNumber + 7 - weekday) % 7;
        const date = new Date(currentDate.getTime());
        date.setDate(date.getDate() + daysUntilWeekday);
        if (date) {
            const startTime = new Date(date);
            startTime.setHours(startHours, startMinutes, 0);
            const endTime = new Date(date);
            endTime.setHours(endHours, endMinutes, 0);
            const event = {
                title: data.course,
                start: new Date(startTime),
                end: new Date(endTime),
            };
            events.push(event);
        }
        currentDate.setDate(currentDate.getDate() + 7);
    }

    return events;
};

export const formatDate = (givenDate) => {
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - new Date(givenDate).getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days === 0) {
        return new Date(givenDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
        return new Date(givenDate).toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
};

// const formatDate = (dateString) => {
//  2021-12-31T23:00:00.000Z to 31, December, 2021
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const month = date.toLocaleString('default', { month: 'long' });
//     const year = date.getFullYear();
//     return `${day}, ${month} ${year}`;
// };

export const addLabelsToData = (data) => {
    const combinedArray = [];

    const addLabel = (dataArray, label) => {
        dataArray?.forEach((item) => {
            const itemWithLabel = { ...item, label };
            combinedArray.push(itemWithLabel);
        });
    };

    addLabel(data?.bulletins, 'Bulletin');

    addLabel(data?.mails, 'Mail');
    addLabel(data?.externalMemos, 'External Memo');
    addLabel(data?.internalMemos, 'Internal Memo');
    addLabel(data?.notices, 'Notice');

    return combinedArray;
};

export const getLabelBadge = (label) => {
    let bgColor, textColor, labelText;

    switch (label) {
        case 'Internal Memo':
            bgColor = '#FDF2F8';
            textColor = '#EB4898';
            labelText = label;
            break;
        case 'Notice':
            bgColor = '#ECFDF5';
            textColor = '#10B981';
            labelText = label;
            break;
        case 'Mail':
            bgColor = '#EFF6FF';
            textColor = '#3B82F6';
            labelText = label;
            break;
        case 'External Memo':
            bgColor = '#F5F3FF';
            textColor = '#EB4898';
            labelText = label;
            break;
        case 'Memo':
            bgColor = '#EFF6FF';
            textColor = '#3B82F6';
            labelText = label;
            break;
        case 'Letter':
            bgColor = '#F5F3FF';
            textColor = '#EB4898';
            labelText = label;
            break;
        default:
            bgColor = '#F5F3FF';
            textColor = '#8B5CF6';
            labelText = label;
            break;
    }

    const StatusContainer = styled.div`
        padding: 2px 4px;
        border-radius: 4px;
        background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
        width: fit-content;
    `;

    return (
        <StatusContainer bgColor={bgColor}>
            <Text color={textColor} weight="500" size="1.2rem">
                {labelText}
            </Text>
        </StatusContainer>
    );
};

export const sortData = (newData) => {
    const sortedData = [...newData];
    sortedData?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return sortedData;
};

export const capitalizeFirstLetter = (str = '') => {
    // e.g 'let me leave' => Let Me Leave OR LET => Let
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
export const capitalizeEachWord = (sentence = '') => {
    let words = sentence.split(' ');
    let capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
};

export const getStatus = (label) => {
    let bgColor, textColor, labelText;

    switch (label) {
        case 'shared':
            bgColor = '#ECFDF5';
            textColor = '#059669';
            labelText = label;
            break;
        default:
            bgColor = '#FEF2F2';
            textColor = '#DC2626';
            labelText = label;
            break;
    }

    const StatusContainer = styled.div`
        padding: 2px 4px;
        border-radius: 4px;
        background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
        width: fit-content;
    `;

    return (
        <StatusContainer bgColor={bgColor}>
            <Text color={textColor} weight="500" size="1.2rem">
                {capitalizeEachWord(labelText)?.replace('-', ' ')}
            </Text>
        </StatusContainer>
    );
};

export const getDegreeStructs = (academicStructure) => {
    const splitStruct = academicStructure?.split('-');

    const firstDegreeStruct = splitStruct?.[0];
    const secondDegreeStruct = splitStruct?.[1];
    const thirdDegreeStruct = splitStruct?.[2] || '';

    return { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct };
};

export const getDegreeInfo = (details, structureField = 'structure') => {
    const academicStructure = details?.[structureField];
    const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(academicStructure);

    const firstDegree = details?.[firstDegreeStruct] || '';
    const secondDegree =
        secondDegreeStruct === 'program'
            ? details?.programme || details?.subProgram || details?.subProgran
            : details?.[secondDegreeStruct] || '';
    const thirdDegree = details?.[thirdDegreeStruct] || '';

    return { firstDegree, secondDegree, thirdDegree };
};

export const formatTime = (time) => {
    // if 10:00:00 return else add :00 to 10:00
    if (time.length > 5) return time;
    return time + ':00';
};

export const handleImagePreview = (url) => {
    if (url) {
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>Image Preview</title>
                        <style>
                            body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
                            img { max-width: 100%; max-height: 100%; }
                        </style>
                    </head>
                    <body>
                        <img src="${url}" alt="Preview"/>
                    </body>
                </html>
            `);
            newWindow.document.close();
        }
    } else {
        toast.error('Failed to open preview.');
    }
};

export const handleVideoPreview = (videoSrc) => {
    if (videoSrc) {
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>Video Preview</title>
                        <style>
                            body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
                            img { max-width: 100%; max-height: 100%; }
                        </style>
                    </head>
                    <body>
                      <video width="400" controls>
                    <source src='${videoSrc}' />
                    Your browser does not support the video tag.
                </video>
                    </body>
                </html>
            `);
            newWindow.document.close();
        }
    } else {
        toast.error('Failed to open preview.');
    }
};

export const handlePdfPreview = async (url) => {
    try {
        const response = await fetch(url);
        const data = await response.blob();
        const base64Data = await convertBlobToBase64(data);
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>PDF Preview</title>
                        <style>
                            body {
                                margin: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                                background-color: #f0f2f5;
                            }
                            .pdf-container {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .pdf-document {
                                width: 100%;
                                height: 100%;
                                border: none;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="pdf-container">
                            <iframe class="pdf-document" src="data:application/pdf;base64,${base64Data}" type="application/pdf"></iframe>
                        </div>
                    </body>
                </html>
            `);
            newWindow.document.close();
        } else {
            alert('Please allow popups for this website');
        }
    } catch (error) {
        console.error('Error opening PDF in new tab:', error);
    }
};

const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const handleDownload = async (url, fileName) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(objectUrl);
        toast.success('Download successful!');
    } catch (error) {
        toast.error(`Error occurred during download: ${error.message}`);
    }
};

export const docTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
];

export const videoTypes = ['video/mp4', 'video/x-msvideo', 'video/quicktime', 'video/webm', 'video/x-matroska'];

export const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

export const allowedTypes = [...docTypes, ...videoTypes, imageTypes];

export const getFileIcon = (fileType) => {
    if (!fileType) return;
    if (fileType?.includes('xls')) return <ExcelIcon height={24} width={24} />;
    if (fileType?.includes('ppt')) return <PPTXIcon />;
    if (fileType?.includes('pdf')) return <PDFIcon />;
    if (fileType?.includes('doc') || fileType.includes('docx')) return <MSWordIcon />;
    if (imageTypes?.includes(fileType)) return <ImageIcon />;
    if (videoTypes?.includes(fileType)) return <VideoIcon />;
    return null;
};
